import PropTypes from 'prop-types'
import { FEE_MGMT_COLUMNS } from './Columns'

const REQUIRED_LOCATION_COLUMNS = [
  FEE_MGMT_COLUMNS.LOCATION_TYPE.columnKey,
  FEE_MGMT_COLUMNS.STATES.columnKey,
  FEE_MGMT_COLUMNS.COUNTIES.columnKey,
  FEE_MGMT_COLUMNS.ZIP_CODES.columnKey,
]

const REQUIRED_VENDOR_COLUMNS = [FEE_MGMT_COLUMNS.VENDOR.columnKey]
const REQUIRED_BRANCH_COLUMNS = [FEE_MGMT_COLUMNS.BRANCH.columnKey]

const REQUIRED_LOCATION_COLUMNS_READABLE = [
  FEE_MGMT_COLUMNS.LOCATION_TYPE.readable,
  FEE_MGMT_COLUMNS.STATES.readable,
  FEE_MGMT_COLUMNS.COUNTIES.readable,
  FEE_MGMT_COLUMNS.ZIP_CODES.readable,
]
const REQUIRED_VENDOR_COLUMNS_READABLE = [FEE_MGMT_COLUMNS.VENDOR.readable]
const REQUIRED_BRANCH_COLUMNS_READABLE = [FEE_MGMT_COLUMNS.BRANCH.readable]

const REQUIRED_LOCATION_COLUMNS_SNAKE_CASE = [
  FEE_MGMT_COLUMNS.LOCATION_TYPE.snakeCaseKey,
  FEE_MGMT_COLUMNS.STATES.snakeCaseKey,
  FEE_MGMT_COLUMNS.COUNTIES.snakeCaseKey,
  FEE_MGMT_COLUMNS.ZIP_CODES.snakeCaseKey,
]
const REQUIRED_VENDOR_COLUMNS_SNAKE_CASE = [FEE_MGMT_COLUMNS.VENDOR.snakeCaseKey]
const REQUIRED_BRANCH_COLUMNS_SNAKE_CASE = [FEE_MGMT_COLUMNS.BRANCH.snakeCaseKey]

const OVERRIDE_TYPES = {
  LOCATION: { value: 'location', readable: 'Location' },
  VENDOR: { value: 'vendor', readable: 'Vendor' },
  VENDOR_LOCATION: { value: 'vendorLocation', readable: 'Vendor Location' },
  BRANCH: { value: 'branch', readable: 'Branch' },
  BRANCH_LOCATION: { value: 'branchLocation', readable: 'Branch Location' },
  BRANCH_VENDOR: { value: 'branchVendor', readable: 'Branch Vendor' },
  BRANCH_VENDOR_LOCATION: { value: 'branchVendorLocation', readable: 'Branch Vendor Location' },
}

const API_OVERRIDE_TYPE_MAPPING = {
  LOCATION: ['product', 'location'],
  VENDOR: ['product', 'panelist'],
  VENDOR_LOCATION: ['product', 'location', 'panelist'],
  BRANCH: ['product', 'branch'],
  BRANCH_LOCATION: ['product', 'branch', 'location'],
  BRANCH_VENDOR: ['product', 'branch', 'panelist'],
  BRANCH_VENDOR_LOCATION: ['product', 'branch', 'location', 'panelist'],
}

const ALL_ENTITY_TYPES = [
  ...API_OVERRIDE_TYPE_MAPPING.LOCATION,
  ...API_OVERRIDE_TYPE_MAPPING.VENDOR,
  ...API_OVERRIDE_TYPE_MAPPING.BRANCH,
  ...API_OVERRIDE_TYPE_MAPPING.BRANCH_LOCATION,
  ...API_OVERRIDE_TYPE_MAPPING.BRANCH_VENDOR,
  ...API_OVERRIDE_TYPE_MAPPING.BRANCH_VENDOR_LOCATION,
]

const REQUIRED_COLUMNS_BY_OVERRIDE_TYPE = {
  common: [FEE_MGMT_COLUMNS.PRODUCT_NAME.columnKey],
  [OVERRIDE_TYPES.LOCATION.value]: REQUIRED_LOCATION_COLUMNS,
  [OVERRIDE_TYPES.VENDOR.value]: REQUIRED_VENDOR_COLUMNS,
  [OVERRIDE_TYPES.VENDOR_LOCATION.value]: [...REQUIRED_VENDOR_COLUMNS, ...REQUIRED_LOCATION_COLUMNS],
  [OVERRIDE_TYPES.BRANCH.value]: REQUIRED_BRANCH_COLUMNS,
  [OVERRIDE_TYPES.BRANCH_LOCATION.value]: [...REQUIRED_BRANCH_COLUMNS, ...REQUIRED_LOCATION_COLUMNS],
  [OVERRIDE_TYPES.BRANCH_VENDOR.value]: [...REQUIRED_BRANCH_COLUMNS, ...REQUIRED_VENDOR_COLUMNS],
  [OVERRIDE_TYPES.BRANCH_VENDOR_LOCATION.value]: [
    ...REQUIRED_BRANCH_COLUMNS,
    ...REQUIRED_VENDOR_COLUMNS,
    ...REQUIRED_LOCATION_COLUMNS,
  ],
}

const REQUIRED_COLUMNS_BY_OVERRIDE_TYPE_READABLE = {
  common: [FEE_MGMT_COLUMNS.PRODUCT_NAME.readable],
  [OVERRIDE_TYPES.LOCATION.value]: REQUIRED_LOCATION_COLUMNS_READABLE,
  [OVERRIDE_TYPES.VENDOR.value]: REQUIRED_VENDOR_COLUMNS_READABLE,
  [OVERRIDE_TYPES.VENDOR_LOCATION.value]: [...REQUIRED_VENDOR_COLUMNS_READABLE, ...REQUIRED_LOCATION_COLUMNS_READABLE],
  [OVERRIDE_TYPES.BRANCH.value]: REQUIRED_BRANCH_COLUMNS_READABLE,
  [OVERRIDE_TYPES.BRANCH_LOCATION.value]: [...REQUIRED_BRANCH_COLUMNS_READABLE, ...REQUIRED_LOCATION_COLUMNS_READABLE],
  [OVERRIDE_TYPES.BRANCH_VENDOR.value]: [...REQUIRED_BRANCH_COLUMNS_READABLE, ...REQUIRED_VENDOR_COLUMNS_READABLE],
  [OVERRIDE_TYPES.BRANCH_VENDOR_LOCATION.value]: [
    ...REQUIRED_BRANCH_COLUMNS_READABLE,
    ...REQUIRED_VENDOR_COLUMNS_READABLE,
    ...REQUIRED_LOCATION_COLUMNS_READABLE,
  ],
}

const REQUIRED_COLUMNS_BY_OVERRIDE_TYPE_SNAKE_CASE = {
  common: [FEE_MGMT_COLUMNS.PRODUCT_NAME.snakeCaseKey],
  [OVERRIDE_TYPES.LOCATION.value]: REQUIRED_LOCATION_COLUMNS_SNAKE_CASE,
  [OVERRIDE_TYPES.VENDOR.value]: REQUIRED_VENDOR_COLUMNS_SNAKE_CASE,
  [OVERRIDE_TYPES.VENDOR_LOCATION.value]: [
    ...REQUIRED_VENDOR_COLUMNS_SNAKE_CASE,
    ...REQUIRED_LOCATION_COLUMNS_SNAKE_CASE,
  ],
  [OVERRIDE_TYPES.BRANCH.value]: REQUIRED_BRANCH_COLUMNS_SNAKE_CASE,
  [OVERRIDE_TYPES.BRANCH_LOCATION.value]: [
    ...REQUIRED_BRANCH_COLUMNS_SNAKE_CASE,
    ...REQUIRED_LOCATION_COLUMNS_SNAKE_CASE,
  ],
  [OVERRIDE_TYPES.BRANCH_VENDOR.value]: [...REQUIRED_BRANCH_COLUMNS_SNAKE_CASE, ...REQUIRED_VENDOR_COLUMNS_SNAKE_CASE],
  [OVERRIDE_TYPES.BRANCH_VENDOR_LOCATION.value]: [
    ...REQUIRED_BRANCH_COLUMNS_SNAKE_CASE,
    ...REQUIRED_VENDOR_COLUMNS_SNAKE_CASE,
    ...REQUIRED_LOCATION_COLUMNS_SNAKE_CASE,
  ],
}
const INITIAL_SUMMARY_COUNTS = {
  [OVERRIDE_TYPES.LOCATION.value]: 0,
  [OVERRIDE_TYPES.VENDOR.value]: 0,
  [OVERRIDE_TYPES.VENDOR_LOCATION.value]: 0,
  [OVERRIDE_TYPES.BRANCH.value]: 0,
  [OVERRIDE_TYPES.BRANCH_LOCATION.value]: 0,
  [OVERRIDE_TYPES.BRANCH_VENDOR.value]: 0,
  [OVERRIDE_TYPES.BRANCH_VENDOR_LOCATION.value]: 0,
}

const INITIAL_SUMMARY_PROPS = {
  [OVERRIDE_TYPES.LOCATION.value]: [],
  [OVERRIDE_TYPES.VENDOR.value]: [],
  [OVERRIDE_TYPES.VENDOR_LOCATION.value]: [],
  [OVERRIDE_TYPES.BRANCH.value]: [],
  [OVERRIDE_TYPES.BRANCH_LOCATION.value]: [],
  [OVERRIDE_TYPES.BRANCH_VENDOR.value]: [],
  [OVERRIDE_TYPES.BRANCH_VENDOR_LOCATION.value]: [],
}

const SUMMARY_PROPS_SHAPE = {
  [OVERRIDE_TYPES.LOCATION.value]: PropTypes.array.isRequired,
  [OVERRIDE_TYPES.VENDOR.value]: PropTypes.array.isRequired,
  [OVERRIDE_TYPES.VENDOR_LOCATION.value]: PropTypes.array.isRequired,
  [OVERRIDE_TYPES.BRANCH.value]: PropTypes.array.isRequired,
  [OVERRIDE_TYPES.BRANCH_LOCATION.value]: PropTypes.array.isRequired,
  [OVERRIDE_TYPES.BRANCH_VENDOR.value]: PropTypes.array.isRequired,
  [OVERRIDE_TYPES.BRANCH_VENDOR_LOCATION.value]: PropTypes.array.isRequired,
}

const getRequiredEntityTypesByOverrideType = (overrideType) => {
  return Object.entries(OVERRIDE_TYPES).reduce((acc, [key, typeObj]) => {
    if (typeObj.value === overrideType) {
      return API_OVERRIDE_TYPE_MAPPING[key] || []
    }
    return acc
  }, [])
}

export {
  REQUIRED_LOCATION_COLUMNS_READABLE,
  REQUIRED_VENDOR_COLUMNS_READABLE,
  REQUIRED_BRANCH_COLUMNS_READABLE,
  OVERRIDE_TYPES,
  API_OVERRIDE_TYPE_MAPPING,
  ALL_ENTITY_TYPES,
  REQUIRED_COLUMNS_BY_OVERRIDE_TYPE,
  REQUIRED_COLUMNS_BY_OVERRIDE_TYPE_READABLE,
  REQUIRED_COLUMNS_BY_OVERRIDE_TYPE_SNAKE_CASE,
  INITIAL_SUMMARY_COUNTS,
  INITIAL_SUMMARY_PROPS,
  SUMMARY_PROPS_SHAPE,
  getRequiredEntityTypesByOverrideType,
}
