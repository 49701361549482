import { useState, useEffect, useCallback, useContext } from 'react'
import GridDataContext from '../context/GridDataContext'
import { FEE_MGMT_COLUMNS } from '../constants/Columns'

export const useEntityMapping = (field, api, rowData) => {
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(true)
  const { mappingData } = useContext(GridDataContext)

  const getOptions = useCallback(() => {
    if (!field || !mappingData) {
      return []
    }

    switch (field) {
      case FEE_MGMT_COLUMNS.PRODUCT_NAME.columnKey:
        return mappingData.products.map((product) => ({
          value: product.description,
          label: product.description,
        }))
      case FEE_MGMT_COLUMNS.BRANCH.columnKey:
        return mappingData.branches.map((branch) => ({
          value: branch.name,
          label: branch.name,
        }))
      case FEE_MGMT_COLUMNS.VENDOR.columnKey:
        return mappingData.panelists.map((panelist) => ({
          value: panelist.name,
          label: panelist.name,
        }))
      case FEE_MGMT_COLUMNS.STATES.columnKey:
        // Sort states alphabetically by their code
        return Object.entries(mappingData.state_and_counties_map)
          .map(([code, name]) => ({
            value: code,
            label: code,
          }))
          .sort((a, b) => a.value.localeCompare(b.value))
      case FEE_MGMT_COLUMNS.COUNTIES.columnKey: {
        // Check if we have row data with a state value
        const rowStateValue = rowData?.[FEE_MGMT_COLUMNS.STATES.columnKey]

        // If row has a state value, show only counties for that state
        if (rowStateValue) {
          const stateData = mappingData.state_and_counties_map[rowStateValue]
          if (!stateData) {
            return []
          }

          // Convert counties object to array of options
          return Object.entries(stateData.counties)
            .map(([countyKey, countyData]) => ({
              value: countyData.readable || countyKey,
              label: countyData.readable || countyKey,
            }))
            .sort((a, b) => a.label.localeCompare(b.label))
        }

        // If no row state value is provided, show all counties with state prefixes
        const allCountiesWithStates = []

        Object.entries(mappingData.state_and_counties_map).forEach(([state, stateData]) => {
          Object.entries(stateData.counties).forEach(([countyKey, countyData]) => {
            allCountiesWithStates.push({
              value: countyData.readable || countyKey,
              label: `${state} - ${countyData.readable || countyKey}`,
              state: state,
            })
          })
        })

        return allCountiesWithStates.sort((a, b) => a.label.localeCompare(b.label))
      }
      default:
        return []
    }
  }, [field, mappingData, rowData])

  const refreshOptions = useCallback(() => {
    setLoading(true)
    const newOptions = getOptions()
    setOptions(newOptions)
    setLoading(false)
  }, [getOptions])

  useEffect(() => {
    refreshOptions()
  }, [refreshOptions])

  // Add effect to update county options when state changes
  useEffect(() => {
    if (field === FEE_MGMT_COLUMNS.COUNTIES.columnKey) {
      const onFilterChanged = () => {
        refreshOptions()
      }

      api?.addEventListener('filterChanged', onFilterChanged)
      return () => {
        api?.removeEventListener('filterChanged', onFilterChanged)
      }
    }
  }, [field, api, refreshOptions])

  // Also refresh when rowData changes, specifically for counties
  useEffect(() => {
    if (field === FEE_MGMT_COLUMNS.COUNTIES.columnKey && rowData) {
      refreshOptions()
    }
  }, [field, rowData, refreshOptions])

  return {
    options,
    loading,
    refreshOptions,
  }
}
