import { useMemo, useState, useEffect, useRef } from 'react'
import { useValidation } from './useValidation'
import { useGridData } from '../context/GridDataContext'
import { getRequiredEntityTypesByOverrideType } from '../constants/OverrideTypes'

export const useRowData = ({ initialRowData, overrideType, selectedFields, gridApiRef }) => {
  const { validateRows } = useValidation()
  const { mappingData, isLoadingMappingData, fetchMappingData } = useGridData()
  const [currentGridData, setCurrentGridData] = useState([])
  const [updateCounter, setUpdateCounter] = useState(0)

  // Use ref to track what we've already fetched - this won't trigger re-renders
  const fetchedTypesRef = useRef(new Set())

  // Determine required entity types based on override type
  const requiredEntityTypes = useMemo(() => {
    if (!overrideType) {
      return []
    }

    return getRequiredEntityTypesByOverrideType(overrideType)
  }, [overrideType])

  // Single effect to handle all mapping data fetching logic
  useEffect(() => {
    // Skip if no override type or no required types
    if (!overrideType || !requiredEntityTypes.length) {
      return
    }

    // Check if we already fetched for this type
    if (fetchedTypesRef.current.has(overrideType)) {
      return
    }

    // Check if we already have the required data
    const hasRequiredData =
      mappingData && mappingData.panelists && mappingData.products && mappingData.state_and_counties_map

    if (hasRequiredData) {
      // Mark as fetched even though we didn't need to fetch
      fetchedTypesRef.current.add(overrideType)
      return
    }

    // We need to fetch the data
    fetchMappingData(requiredEntityTypes)
    fetchedTypesRef.current.add(overrideType)
  }, [overrideType, requiredEntityTypes, mappingData, fetchMappingData])

  const sortRows = useMemo(() => {
    return (rows) => {
      return [...rows].sort((a, b) => {
        // First priority: error rows
        if (a.validationErrors && !b.validationErrors) {
          return -1
        }
        if (!a.validationErrors && b.validationErrors) {
          return 1
        }

        // Second priority: duplicate rows
        if (a.isDuplicateRow && !b.isDuplicateRow) {
          return -1
        }
        if (!a.isDuplicateRow && b.isDuplicateRow) {
          return 1
        }

        // If both have the same status, maintain original order
        return 0
      })
    }
  }, [])

  useEffect(() => {
    if (!gridApiRef?.current || updateCounter === 0) {
      setCurrentGridData(initialRowData || [])
      return
    }
    const rowData = []
    gridApiRef.current.forEachNode((node) => {
      if (node.data) {
        rowData.push({ ...node.data })
      }
    })
    setCurrentGridData(rowData)
  }, [gridApiRef, updateCounter, initialRowData, overrideType])

  const { validatedRows, validationSummary } = useMemo(() => {
    // Don't validate if we're still loading mapping data or don't have grid data
    if (isLoadingMappingData || !currentGridData?.length) {
      return {
        validatedRows: currentGridData || [],
        validationSummary: {
          errorCounts: {},
          errorRows: {},
          totalErrorCount: 0,
          hasErrors: false,
        },
      }
    }

    const result = validateRows(currentGridData, {
      overrideType,
      overrideFields: selectedFields,
      mappingData,
    })

    // Sort rows by isDuplicateRow property (duplicates first)
    const sortedRows = sortRows(result?.rows)

    return {
      validatedRows: sortedRows,
      validationSummary: result?.summary || {
        errorCounts: {},
        errorRows: {},
        totalErrorCount: 0,
        hasErrors: false,
      },
    }
  }, [currentGridData, overrideType, selectedFields, mappingData, isLoadingMappingData, validateRows, sortRows])

  return {
    validatedRows: validatedRows || [],
    validationSummary: validationSummary || {},
    refreshValidation: () => {
      setUpdateCounter((prev) => prev + 1)
    },
  }
}
