import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'
import {
  DeleteFilled,
  DeleteOutlined,
  CopyFilled,
  StopFilled,
  ExclamationCircleFilled,
  WarningFilled,
  ClusterOutlined,
} from '@ant-design/icons'
import { FeeValidationErrorTypes, ERROR_MESSAGE_TEMPLATES, LOCATION_TYPES } from '../constants/Validation'

const NON_BREAKING_SPACE = '\u00A0'
const ICON_STYLE = {
  fontSize: '18px',
  position: 'relative',
  top: '1px',
}

const formatCommaSeparatedValues = (value) => {
  if (!value) {
    return value
  }
  // Replace any comma followed by a character with a comma, space, and that character
  return String(value).replace(/,([^\s])/g, ', $1')
}

const formatErrorMessage = (errorType, columnKey, details) => {
  // Get the template
  const templates = ERROR_MESSAGE_TEMPLATES[errorType] || {}
  const template = templates[columnKey] || templates.default || `${errorType} error`

  // No details, return template directly
  const hasDetails = details && Object.keys(details).length > 0
  if (!hasDetails) {
    return template
  }

  // Replace template variables with values from details
  return template.replace(/\${(\w+)}/g, (match, key) => {
    if (key === 'rowNumbers' && Array.isArray(details.rowNumbers)) {
      return details.rowNumbers.join(', ')
    }
    return details[key] !== undefined ? details[key] : match
  })
}

const getValidationIcon = (validationState) => {
  if (!validationState) {
    return null
  }

  // Duplicate rows always take precedence
  if (validationState.isDuplicateRow) {
    const tooltipMessage = validationState.duplicateOf?.message || 'Duplicate row'

    return (
      <Tooltip title={tooltipMessage} placement="right">
        <CopyFilled
          style={{
            ...ICON_STYLE,
            color: 'rgba(255, 255, 255, 0.9)',
          }}
        />
      </Tooltip>
    )
  }

  // Check if there are any errors in the details
  const hasErrors = Object.values(validationState.validationErrors || {}).some(
    (error) => error.details && Object.keys(error.details).length > 0,
  )

  // If no errors, return null (don't show any icon)
  if (!hasErrors) {
    return null
  }

  // Get all unique error types from details
  const allErrorTypes = new Set()
  Object.values(validationState.validationErrors || {}).forEach((columnErrors) => {
    if (columnErrors.details) {
      Object.keys(columnErrors.details).forEach((errorType) => allErrorTypes.add(errorType))
    }
  })

  const errorTypes = Array.from(allErrorTypes)

  // For multiple errors (excluding duplicates), show stop icon in red
  if (errorTypes.length > 1) {
    return <StopFilled style={{ ...ICON_STYLE, color: '#ff4d4f' }} />
  }

  // For single error types
  const errorType = errorTypes[0]
  switch (errorType) {
    case FeeValidationErrorTypes.MISSING_VALUE:
      return <ExclamationCircleFilled style={{ ...ICON_STYLE, color: '#ff4d4f' }} />
    case FeeValidationErrorTypes.INVALID_FORMAT:
      return <WarningFilled style={{ ...ICON_STYLE, color: '#faad14' }} />
    case FeeValidationErrorTypes.DUPLICATE_STATE:
    case FeeValidationErrorTypes.DUPLICATE_COUNTY:
    case FeeValidationErrorTypes.DUPLICATE_ZIP:
      return <ClusterOutlined style={{ ...ICON_STYLE, color: '#1890ff' }} />
    default:
      return <WarningFilled style={{ ...ICON_STYLE, color: '#faad14' }} />
  }
}

export const ErrorCellRenderer = memo((props) => {
  if (!props.data) {
    return null
  }

  const icon = getValidationIcon(props.data)
  if (!icon) {
    return null
  }

  return (
    <div className="error-cell" style={{ cursor: 'default' }}>
      {icon}
    </div>
  )
})

export const DeleteCellRenderer = memo((props) => {
  if (!props.data) {
    return <span className="delete-cell" />
  }
  const isDuplicateRow = props.data?.isDuplicateRow
  const isUserMarkedForDelete = props.data?.userMarkedForDelete
  const iconStyle = {
    cursor: isDuplicateRow ? 'not-allowed' : 'pointer',
  }

  // Return icon with tooltip for duplicate rows
  if (isDuplicateRow) {
    return (
      <div className="delete-cell" style={iconStyle}>
        <Tooltip title="Duplicate rows cannot be unmarked" placement="top">
          <DeleteFilled className="delete-marked" />
        </Tooltip>
      </div>
    )
  }

  // Return normal icon for regular rows
  return (
    <div className="delete-cell">
      {isUserMarkedForDelete ? <DeleteFilled className="delete-marked" /> : <DeleteOutlined />}
    </div>
  )
})

export const CellWithErrorTooltip = memo((props) => {
  const { value, data, colDef } = props
  const field = colDef.field

  // First check if this is a duplicate row - if so, don't show error tooltips
  if (data?.isDuplicateRow) {
    return (
      <Tooltip title={value} mouseEnterDelay={0.3} overlayClassName="text-overflow-tooltip">
        <span className="cell-content">{value}</span>
      </Tooltip>
    )
  }

  const errors = data?.validationErrors?.[field]
  const rowNumber = data?.rowNumber
  const toolTipKey = `${rowNumber}-${field}`

  if (!errors || !errors.details || Object.keys(errors.details).length === 0) {
    return (
      <Tooltip title={value} mouseEnterDelay={0.3} overlayClassName="text-overflow-tooltip">
        <span className="cell-content">{value}</span>
      </Tooltip>
    )
  }

  // Generate error messages
  const errorMessages = []

  // Process each error type from the details object
  Object.keys(errors.details).forEach((errorType) => {
    const details = errors.details[errorType]?.values || []

    if (details.length > 0) {
      details.forEach((detail) => {
        const message = formatErrorMessage(errorType, field, detail)
        errorMessages.push(message)
      })
    } else {
      // No details, use the error type directly
      errorMessages.push(formatErrorMessage(errorType, field))
    }
  })

  if (errorMessages.length === 0) {
    return (
      <Tooltip title={value} mouseEnterDelay={0.3} overlayClassName="text-overflow-tooltip">
        <span className="cell-content">{value}</span>
      </Tooltip>
    )
  }

  return (
    <Tooltip
      title={
        <div>
          {errorMessages.map((msg, i) => (
            <div key={toolTipKey + i}>{msg}</div>
          ))}
        </div>
      }
      placement="top"
      mouseEnterDelay={0.3}
      overlayClassName="error-tooltip"
    >
      <div style={{ width: '100%', height: '100%' }}>
        <Tooltip title={value} placement="bottom" mouseEnterDelay={0.3} overlayClassName="text-overflow-tooltip">
          <span className="cell-content">{value || NON_BREAKING_SPACE}</span>
        </Tooltip>
      </div>
    </Tooltip>
  )
})

export const LocationTypeCellRenderer = memo((props) => {
  const { value, data, colDef } = props
  const field = colDef.field
  const errors = data?.validationErrors?.[field]

  // Basic style based on location type
  let locationStyle = {}
  if (value === LOCATION_TYPES.STATE) {
    locationStyle = { color: '#5c9bfa' } // Blue for multi-state
  } else if (value === LOCATION_TYPES.COUNTY) {
    locationStyle = { color: '#52c41a' } // Green for county
  } else if (value === LOCATION_TYPES.ZIP) {
    locationStyle = { color: '#fa8c16' } // Orange for zip
  }

  // If there are errors, use CellWithErrorTooltip
  if (errors && Object.keys(errors.details || {}).length > 0) {
    return <CellWithErrorTooltip {...props} />
  }

  // Otherwise, display with style
  return <span style={locationStyle}>{value}</span>
})

export const CommaSeparatedValuesCellRenderer = memo((props) => {
  const formattedValue = formatCommaSeparatedValues(props.value)
  return <CellWithErrorTooltip {...props} value={formattedValue} />
})

CellWithErrorTooltip.propTypes = {
  value: PropTypes.any,
  data: PropTypes.object,
  colDef: PropTypes.object,
}

CellWithErrorTooltip.defaultProps = {
  value: '',
  data: {},
  colDef: {},
}

ErrorCellRenderer.propTypes = {
  data: PropTypes.object,
}

ErrorCellRenderer.defaultProps = {
  data: undefined,
}

DeleteCellRenderer.propTypes = {
  data: PropTypes.object,
  context: PropTypes.shape({
    onToggleDelete: PropTypes.func.isRequired,
  }),
}

DeleteCellRenderer.defaultProps = {
  data: undefined,
  context: undefined,
}

LocationTypeCellRenderer.propTypes = {
  value: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  colDef: PropTypes.object.isRequired,
}

LocationTypeCellRenderer.defaultProps = {
  value: '',
  data: {},
  colDef: {},
}

CommaSeparatedValuesCellRenderer.propTypes = {
  value: PropTypes.string.isRequired,
}

CommaSeparatedValuesCellRenderer.defaultProps = {
  value: '',
}
