import { OVERRIDE_TYPES } from '../../../pages/feeManagement/constants/OverrideTypes'

// Base selector for the fee management slice
export const selectFeeManagementState = (state) => state?.feeManagement

// Selectors for override sheets
export const selectOverrideSheetsState = (state) => selectFeeManagementState(state)?.overrideSheets

export const selectAllOverrideSheets = (state) => selectOverrideSheetsState(state)?.items || []

export const selectOverrideSheetsLoading = (state) => selectOverrideSheetsState(state)?.isLoading || false

export const selectOverrideSheetsError = (state) => selectOverrideSheetsState(state)?.error

// Selectors for filtered override sheets by type
export const selectOverrideSheetsByType = (state, type) => {
  const sheets = selectAllOverrideSheets(state)
  return sheets.filter((sheet) => sheet.overrideType === type)
}

// Selector for a specific override sheet by ID
export const selectOverrideSheetById = (state, id) => {
  const sheets = selectAllOverrideSheets(state)
  return sheets.find((sheet) => sheet.id === id)
}

// Selector for active override sheets
export const selectActiveOverrideSheets = (state) => {
  const sheets = selectAllOverrideSheets(state)
  return sheets.filter((sheet) => sheet.status === 'active')
}

// Selector for override sheets grouped by type
export const selectOverrideSheetsGroupedByType = (state) => {
  const sheets = selectAllOverrideSheets(state)

  const grouped = {
    [OVERRIDE_TYPES.LOCATION.value]: sheets.filter((sheet) => sheet.overrideType === OVERRIDE_TYPES.LOCATION.value),
    [OVERRIDE_TYPES.VENDOR.value]: sheets.filter((sheet) => sheet.overrideType === OVERRIDE_TYPES.VENDOR.value),
    [OVERRIDE_TYPES.VENDOR_LOCATION.value]: sheets.filter(
      (sheet) => sheet.overrideType === OVERRIDE_TYPES.VENDOR_LOCATION.value,
    ),
    [OVERRIDE_TYPES.BRANCH.value]: sheets.filter((sheet) => sheet.overrideType === OVERRIDE_TYPES.BRANCH.value),
    [OVERRIDE_TYPES.BRANCH_LOCATION.value]: sheets.filter(
      (sheet) => sheet.overrideType === OVERRIDE_TYPES.BRANCH_LOCATION.value,
    ),
    [OVERRIDE_TYPES.BRANCH_VENDOR.value]: sheets.filter(
      (sheet) => sheet.overrideType === OVERRIDE_TYPES.BRANCH_VENDOR.value,
    ),
    [OVERRIDE_TYPES.BRANCH_VENDOR_LOCATION.value]: sheets.filter(
      (sheet) => sheet.overrideType === OVERRIDE_TYPES.BRANCH_VENDOR_LOCATION.value,
    ),
  }

  return grouped
}

// Selector for override sheet statistics
export const selectOverrideSheetStats = (state) => {
  const sheets = selectAllOverrideSheets(state)
  return {
    total: sheets.length,
    active: sheets.filter((sheet) => sheet.status === 'active').length,
    inactive: sheets.filter((sheet) => sheet.status === 'inactive').length,
  }
}

// Selectors for override data
export const selectOverrideDataState = (state) => selectFeeManagementState(state)?.overrideData

export const selectOverrideDataLoading = (state) => state.feeManagement.overrideData.isLoading || false

export const selectOverrideDataError = (state) => state.feeManagement.overrideData.error

export const selectOverrideDataBySheetId = (state, sheetId) => {
  return state.feeManagement.overrideData.data
}

// Selectors for mapping data
export const selectMappingDataState = (state) => selectFeeManagementState(state)?.mappingData

export const selectMappingData = (state) =>
  selectMappingDataState(state)?.data || {
    products: [],
    panelists: [],
    state_and_counties_map: {},
    branches: [],
  }

export const selectMappingDataLoading = (state) => selectMappingDataState(state)?.isLoading || false

export const selectMappingDataError = (state) => selectMappingDataState(state)?.error

export const selectFetchedEntityTypes = (state) => selectMappingDataState(state)?.fetchedEntityTypes || []

export const selectProducts = (state) => selectMappingData(state).products || []

export const selectPanelists = (state) => selectMappingData(state).panelists || []

export const selectStateAndCountiesMap = (state) => selectMappingData(state).state_and_counties_map || {}

export const selectBranches = (state) => selectMappingData(state).branches || []

// Selectors for create override
export const selectCreateOverrideState = (state) => selectFeeManagementState(state)?.createOverride

export const selectCreateOverrideResult = (state) => selectCreateOverrideState(state)?.result

export const selectCreateOverrideSuccess = (state) => selectCreateOverrideState(state)?.success || false

export const selectCreateOverrideLoading = (state) => selectCreateOverrideState(state)?.isLoading || false

export const selectCreateOverrideError = (state) => selectCreateOverrideState(state)?.error
