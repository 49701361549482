import React, { createContext, useContext, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { fetchMappingData } from '../../../store/reducers/feeManagement/feeManagementSlice'
import {
  selectMappingData,
  selectMappingDataLoading,
  selectMappingDataError,
} from '../../../store/reducers/feeManagement/feeManagementSelectors'
import { FEE_MGMT_COLUMNS, ROW_NUMBER_COL, OVERRIDE_COLUMNS_KEY_TO_SNAKE_KEY } from '../constants/Columns'
import {
  ALL_ENTITY_TYPES,
  REQUIRED_COLUMNS_BY_OVERRIDE_TYPE_SNAKE_CASE,
  getRequiredEntityTypesByOverrideType,
} from '../constants/OverrideTypes'
import {
  getSaveToApiProcessors,
  getProductNameById,
  getPanelistNameById,
  getBranchNameById,
  LOCATION_TYPE_API_TO_READABLE,
} from '../constants/DataMapping'

// Create the context
const GridDataContext = createContext(null)

// Custom hook to use the context
export const useGridData = () => {
  const context = useContext(GridDataContext)
  if (!context) {
    throw new Error('useGridData must be used within a GridDataProvider')
  }
  return context
}

// Provider component
export const GridDataProvider = ({ children }) => {
  const dispatch = useDispatch()

  // Get mapping data from Redux state using selectors
  const mappingData = useSelector(selectMappingData)
  const isLoadingMappingData = useSelector(selectMappingDataLoading)
  const mappingDataError = useSelector(selectMappingDataError)

  // Function to fetch mapping data based on entity types
  const fetchMappingDataFn = useCallback(
    async (entityTypes) => {
      if (!entityTypes || !entityTypes.length) {
        return
      }

      dispatch(fetchMappingData(entityTypes))
    },
    [dispatch],
  )

  // Automatically fetch all mapping data when the provider mounts
  useEffect(() => {
    // Fetch all entity types to ensure we have complete data
    // Remove duplicates if any
    const uniqueEntityTypes = [...new Set(ALL_ENTITY_TYPES)]

    // Only fetch if we don't already have the data
    if (
      !mappingData ||
      !mappingData.products ||
      !Array.isArray(mappingData.products) ||
      mappingData.products.length === 0 ||
      Object.keys(mappingData).length === 0
    ) {
      fetchMappingDataFn(uniqueEntityTypes)
    }
  }, [fetchMappingDataFn, mappingData])

  // Memoized function to create FIPS code lookup maps
  const createFipsLookupMaps = useCallback(() => {
    const stateFipsToKey = {}
    const countyFipsToReadable = {} // Will now be nested: state_fips -> county_fips -> readable

    if (mappingData?.state_and_counties_map) {
      Object.entries(mappingData.state_and_counties_map).forEach(([stateKey, stateData]) => {
        const stateFips = stateData.fips_code
        stateFipsToKey[stateFips] = stateKey

        if (stateData.counties) {
          // Initialize the nested structure for this state
          countyFipsToReadable[stateFips] = {}

          Object.entries(stateData.counties).forEach(([countyKey, countyData]) => {
            countyFipsToReadable[stateFips][countyData.fips_code] = countyData.readable
          })
        }
      })
    }

    return { stateFipsToKey, countyFipsToReadable }
  }, [mappingData?.state_and_counties_map])

  // Memoized function to map a single item to grid format
  const mapItemToGridFormat = useCallback(
    (item, index, stateFipsToKey, countyFipsToReadable) => {
      // Start with required grid management fields
      const baseFields = {
        [ROW_NUMBER_COL.columnKey]: index + 1,
        [FEE_MGMT_COLUMNS.DELETE.readable]: false,
        [FEE_MGMT_COLUMNS.ERROR.readable]: false,
        validationErrors: {},
        userMarkedForDelete: false,
        isDuplicateRow: false,
      }

      // Map states from FIPS codes to state keys
      const mappedStates = item.states?.map((fipsCode) => stateFipsToKey[fipsCode] || fipsCode) || []

      // Map counties from FIPS codes to readable names, considering state context
      const mappedCounties =
        item.counties?.map((countyFips) => {
          const stateFips = item.states?.[0] // There should only be one state if it has a county mapping
          if (stateFips && countyFipsToReadable[stateFips]?.[countyFips]) {
            return countyFipsToReadable[stateFips][countyFips]
          }
          return countyFips // Fallback to original value if mapping not found
        }) || []

      // Map API fields to column keys
      const mappedFields = {
        [FEE_MGMT_COLUMNS.PRODUCT_NAME.columnKey]: getProductNameById(item.product_id, mappingData),
        [FEE_MGMT_COLUMNS.BRANCH.columnKey]: getBranchNameById(item.branch_id, mappingData),
        [FEE_MGMT_COLUMNS.VENDOR.columnKey]: getPanelistNameById(item.panelist_id, mappingData),
        [FEE_MGMT_COLUMNS.LOCATION_TYPE.columnKey]: LOCATION_TYPE_API_TO_READABLE[item.location_type] || '',
        [FEE_MGMT_COLUMNS.STATES.columnKey]: mappedStates.join(','),
        [FEE_MGMT_COLUMNS.COUNTIES.columnKey]: mappedCounties.join(','),
        [FEE_MGMT_COLUMNS.ZIP_CODES.columnKey]: Array.isArray(item.zips) ? item.zips.join(',') : '',
        [FEE_MGMT_COLUMNS.FEE.columnKey]: item.override_data?.fee,
        [FEE_MGMT_COLUMNS.DUE_DATE.columnKey]: item.override_data?.due_date,
      }

      return {
        ...baseFields,
        ...mappedFields,
      }
    },
    [mappingData],
  )

  // Optimized mapApiDataToGridData function
  const mapApiDataToGridData = useCallback(
    (data) => {
      // Early return for invalid data
      if (!Array.isArray(data)) {
        return []
      }

      // Create FIPS lookup maps once
      const { stateFipsToKey, countyFipsToReadable } = createFipsLookupMaps()

      // Map all items to grid format
      return data.map((item, index) => mapItemToGridFormat(item, index, stateFipsToKey, countyFipsToReadable))
    },
    [createFipsLookupMaps, mapItemToGridFormat],
  )

  // Helper function to check if a field should be included
  const mapOverrideFieldsToApiFields = useCallback((fieldsToOverride) => {
    return fieldsToOverride.map((field) => {
      if (field in OVERRIDE_COLUMNS_KEY_TO_SNAKE_KEY) {
        return (field = OVERRIDE_COLUMNS_KEY_TO_SNAKE_KEY[field])
      }
    })
  }, [])

  const createBaseOverride = useCallback((overrideType, overrideFieldsMap) => {
    const requiredEntityColumns = [
      ...REQUIRED_COLUMNS_BY_OVERRIDE_TYPE_SNAKE_CASE.common,
      ...REQUIRED_COLUMNS_BY_OVERRIDE_TYPE_SNAKE_CASE[overrideType],
    ]

    // Create base override structure
    const baseOverride = requiredEntityColumns.reduce((acc, column) => {
      acc[column] = null
      return acc
    }, {})
    baseOverride.override_data = {}

    // Add override field keys directly to baseOverride with null values
    Object.values(overrideFieldsMap).forEach((field) => {
      baseOverride.override_data[field] = null
    })

    return baseOverride
  }, [])

  // Function to map data between grid and API formats
  const mapGridDataToApi = useCallback(
    (data, fieldsToOverride, overrideType) => {
      if (!Array.isArray(data)) {
        return []
      }

      const processors = getSaveToApiProcessors(mappingData)
      const entityTypes = getRequiredEntityTypesByOverrideType(overrideType)
      const overrideFieldsMap = mapOverrideFieldsToApiFields(fieldsToOverride)
      const baseOverride = createBaseOverride(overrideType, overrideFieldsMap)

      const overrides = data
        .filter((row) => !row.userMarkedForDelete && !row.isDuplicateRow)
        .map((row) => {
          const override = { ...baseOverride }
          const cache = {} // For cross-field processing

          // Hydrate using processors
          Object.entries(processors).forEach(([snakeKey, processor]) => {
            const columnKey = Object.values(FEE_MGMT_COLUMNS).find(
              (column) => column.snakeCaseKey === snakeKey,
            )?.columnKey

            if (columnKey && row[columnKey] !== undefined) {
              const value = processor(row[columnKey], cache)

              // Determine where to store the value
              if (snakeKey in baseOverride) {
                override[snakeKey] = value
              } else if (snakeKey in baseOverride.override_data) {
                override.override_data[snakeKey] = value
              }
            }
          })

          return override
        })

      return { entityTypes, dataTypes: Object.values(overrideFieldsMap), overrides }
    },
    [mappingData, createBaseOverride, mapOverrideFieldsToApiFields],
  )

  // Expose the context value
  const value = {
    mappingData,
    isLoadingMappingData,
    mappingDataError,
    fetchMappingData: fetchMappingDataFn,
    mapGridDataToApi,
    mapApiDataToGridData,
  }

  return <GridDataContext.Provider value={value}>{children}</GridDataContext.Provider>
}

GridDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default GridDataContext
