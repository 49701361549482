import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Typography, Button, Space, Checkbox, Input, Tooltip } from 'antd'
import { Form, Field } from 'react-final-form'
import { InfoCircleOutlined, DownloadOutlined } from '@ant-design/icons'
import FileUploadContainer from '../FileUploadContainer'
import withMemoization from '../../../common/components/MemoWrapper'
import { useValidation } from '../hooks/useValidation'
import { OVERRIDE_COLUMNS_READABLE } from '../constants/Columns'
import { OVERRIDE_TYPES } from '../constants/OverrideTypes'

const { Text } = Typography

const FORM_FIELDS = {
  OVERRIDE_NAME: 'overrideName',
  FIELDS_TO_OVERRIDE: 'fieldsToOverride',
  FILE_NAME: 'fileName',
  FILE_VALIDATION: 'fileValidation',
  GRID_DATA: 'gridData',
}

const CreateOverrideForm = ({ overrideType, initialValues, onSubmit, onBack }) => {
  const [isUploading, setIsUploading] = useState(false)
  const { getRequiredColumns } = useValidation()
  const { requiredColumns } = getRequiredColumns(overrideType, [], true)

  return (
    <div className="configuration-content">
      <div className="configuration-header">
        <h2>Configure Override</h2>
        <Text type="secondary">
          Set up your override by providing a name and selecting which fields to override. Then upload your CSV file
          containing the override data.
        </Text>
      </div>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        subscription={{
          submitting: true,
          pristine: true,
          values: true,
          active: true,
        }}
        keepDirtyOnReinitialize={true}
        destroyOnUnregister={false}
        render={({ form, values = {}, handleSubmit }) => {
          const canProceedToDataReview = !!(
            values?.overrideName?.trim() &&
            values?.fieldsToOverride?.length > 0 &&
            values?.fileName &&
            values?.fileValidation?.missingColumns?.length === 0 &&
            !isUploading
          )

          // Build the list of all required columns
          const allRequiredColumns = [...requiredColumns, ...(values?.fieldsToOverride || [])]

          return (
            <form onSubmit={handleSubmit}>
              <div className="configuration-sections">
                <div className="form-section">
                  <div className="form-item">
                    <div className="section-title">
                      Override Name<span className="required">*</span>
                    </div>
                    <Field name={FORM_FIELDS.OVERRIDE_NAME}>
                      {({ input, meta }) => (
                        <>
                          <Input {...input} placeholder="Enter a name for this override" />
                          {meta.error && meta.touched && <div className="error-text">{meta.error}</div>}
                        </>
                      )}
                    </Field>
                  </div>
                </div>

                <div className="form-section">
                  <div className="section-header">
                    <h3>Override Fields</h3>
                    <Text type="secondary">Select which fields you want to override</Text>
                  </div>
                  <div className="form-item">
                    <Field name={FORM_FIELDS.FIELDS_TO_OVERRIDE}>
                      {({ input, meta }) => (
                        <>
                          <Checkbox.Group
                            className="override-checkbox-group"
                            style={{ display: 'flex', flexDirection: 'column' }}
                            options={OVERRIDE_COLUMNS_READABLE.map((field) => ({
                              label: <span style={{ display: 'flex', alignItems: 'center' }}>{field}</span>,
                              value: field,
                            }))}
                            value={input.value}
                            onChange={input.onChange}
                          />
                          {meta.error && meta.touched && <div className="error-text">{meta.error}</div>}
                        </>
                      )}
                    </Field>
                  </div>
                </div>

                <div className="form-section">
                  <div className="section-header">
                    <h3>
                      File Upload
                      <Tooltip
                        title={
                          <div className="interactive-tooltip">
                            <div className="tooltip-header">
                              <span>Required CSV Columns ({allRequiredColumns.length})</span>
                            </div>
                            <p className="tooltip-description">Your CSV file must include these column headers:</p>
                            <code className="csv-example">{allRequiredColumns.join(', ')}</code>
                            <div className="tooltip-footer">
                              <Button type="link" size="small" icon={<DownloadOutlined />}>
                                Download Template
                              </Button>
                            </div>
                          </div>
                        }
                        placement="topRight"
                        color="#fff"
                        overlayClassName="modern-tooltip"
                        overlayStyle={{ maxWidth: '380px' }}
                      >
                        <InfoCircleOutlined className="info-icon" />
                      </Tooltip>
                    </h3>
                  </div>
                  <div className="form-item">
                    <Field name={FORM_FIELDS.FILE_NAME}>
                      {({ input }) => {
                        return (
                          <>
                            <FileUploadContainer
                              overrideType={overrideType}
                              fileName={values?.fileName}
                              fileValidation={values?.fileValidation}
                              onFileUpload={(rows, metadata) => {
                                form.change(FORM_FIELDS.FILE_NAME, metadata.fileName)
                                form.change(FORM_FIELDS.FILE_VALIDATION, metadata.validation)
                                form.change(FORM_FIELDS.GRID_DATA, rows)
                              }}
                              fieldsToOverride={allRequiredColumns}
                              disabled={!values?.fieldsToOverride?.length || isUploading}
                              onUploadStart={(isStarting) => {
                                setIsUploading(isStarting)
                              }}
                            />
                          </>
                        )
                      }}
                    </Field>
                  </div>
                </div>
              </div>

              <div className="step-actions">
                <Space>
                  <Button onClick={onBack}>Cancel</Button>
                  <Button
                    type="primary"
                    onClick={() => {
                      if (!canProceedToDataReview) {
                        return
                      }
                      handleSubmit(values)
                    }}
                    disabled={!canProceedToDataReview}
                  >
                    Next: Data Review
                  </Button>
                </Space>
              </div>
            </form>
          )
        }}
      />
    </div>
  )
}

CreateOverrideForm.propTypes = {
  overrideType: PropTypes.oneOf(Object.values(OVERRIDE_TYPES).map((type) => type.value)).isRequired,
  initialValues: PropTypes.shape({
    overrideName: PropTypes.string,
    fieldsToOverride: PropTypes.arrayOf(PropTypes.string),
    fileName: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
}

export default withMemoization(CreateOverrideForm, Object.keys(CreateOverrideForm.propTypes))
