import { FEE_MGMT_COLUMNS } from './Columns'
import { ZIP, COUNTY, STATE } from './Validation'

const LOCATION_TYPE_API_TO_READABLE = {
  zip: ZIP,
  county: COUNTY,
  multistate: STATE,
}

const LOCATION_TYPE_READABLE_TO_API = Object.fromEntries(
  Object.entries(LOCATION_TYPE_API_TO_READABLE).map(([key, value]) => [value, key]),
)

const getPanelistNameById = (panelistId, mappingData) => {
  const panelist = mappingData.panelists.find((p) => p.id === panelistId)
  return panelist ? panelist.name : panelistId
}

const getPanelistIdByName = (panelistName, mappingData) => {
  const panelist = mappingData.panelists.find((p) => p.name === panelistName)
  return panelist ? panelist.id : panelistName
}

const getProductNameById = (productId, mappingData) => {
  const product = mappingData.products.find((p) => p.id === productId)
  return product ? product.description : productId
}

const getProductIdByName = (productName, mappingData) => {
  const product = mappingData.products.find((p) => p.description === productName)
  return product ? product.id : productName
}

const getBranchNameById = (branchId, mappingData) => {
  const branch = mappingData.branches.find((b) => b.id === branchId)
  return branch ? branch.name : branchId
}

const getBranchIdByName = (branchName, mappingData) => {
  const branch = mappingData.branches.find((b) => b.name === branchName)
  return branch ? branch.id : branchName
}

const mapCountyToFips = (countyName, states, stateAndCountiesMap) => {
  if (!stateAndCountiesMap || !states.length) {
    return countyName
  }

  const countyNameLower = countyName.toLowerCase()

  // Try primary state first (most common case)
  const primaryState = states[0]
  const primaryStateMapping = stateAndCountiesMap[primaryState]?.counties
  if (primaryStateMapping) {
    const countyKey = Object.keys(primaryStateMapping).find(
      (key) => primaryStateMapping[key].readable.toLowerCase() === countyNameLower,
    )
    if (countyKey) {
      return primaryStateMapping[countyKey].fips_code
    }
  }

  // Try other states if needed
  for (let i = 1; i < states.length; i++) {
    const stateMapping = stateAndCountiesMap[states[i]]?.counties
    if (stateMapping) {
      const countyKey = Object.keys(stateMapping).find(
        (key) => stateMapping[key].readable.toLowerCase() === countyNameLower,
      )
      if (countyKey) {
        return stateMapping[countyKey].fips_code
      }
    }
  }

  return countyName
}

const mapCommaSeparatedStringToArray = (commaSeparatedString) => {
  return (
    commaSeparatedString
      ?.split(',')
      .map((s) => s.trim())
      .filter((s) => s !== '') || []
  )
}

const getSaveToApiProcessors = (mappingData) => {
  return {
    [FEE_MGMT_COLUMNS.PRODUCT_NAME.snakeCaseKey]: (rawValue) => getProductIdByName(rawValue, mappingData),
    [FEE_MGMT_COLUMNS.BRANCH.snakeCaseKey]: (rawValue) => getBranchIdByName(rawValue, mappingData),
    [FEE_MGMT_COLUMNS.VENDOR.snakeCaseKey]: (rawValue) => getPanelistIdByName(rawValue, mappingData),
    [FEE_MGMT_COLUMNS.LOCATION_TYPE.snakeCaseKey]: (rawValue) => LOCATION_TYPE_READABLE_TO_API[rawValue] || null,
    [FEE_MGMT_COLUMNS.STATES.snakeCaseKey]: (rawValue, cache) => {
      const statesRaw = mapCommaSeparatedStringToArray(rawValue)
      cache.statesRaw = statesRaw
      return statesRaw.map((stateCode) => mappingData?.state_and_counties_map?.[stateCode]?.fips_code || stateCode)
    },
    [FEE_MGMT_COLUMNS.COUNTIES.snakeCaseKey]: (rawValue, cache) => {
      if (!cache.statesRaw) {
        console.warn('Processing counties but statesRaw not found in cache.')
        return mapCommaSeparatedStringToArray(rawValue)
      }
      const countiesRaw = mapCommaSeparatedStringToArray(rawValue)
      return countiesRaw.map((countyName) =>
        mapCountyToFips(countyName, cache.statesRaw, mappingData?.state_and_counties_map),
      )
    },
    [FEE_MGMT_COLUMNS.ZIP_CODES.snakeCaseKey]: (rawValue) => mapCommaSeparatedStringToArray(rawValue),
    // Override columns use their snakeCaseKey directly
    [FEE_MGMT_COLUMNS.FEE.snakeCaseKey]: (rawValue) => {
      return rawValue != null && rawValue !== '' ? parseFloat(rawValue) : undefined
    },
    [FEE_MGMT_COLUMNS.DUE_DATE.snakeCaseKey]: (rawValue) => {
      return rawValue != null && rawValue !== '' ? parseInt(rawValue, 10) : undefined
    },
  }
}

export {
  getPanelistNameById,
  getProductNameById,
  getBranchNameById,
  getSaveToApiProcessors,
  LOCATION_TYPE_READABLE_TO_API,
  LOCATION_TYPE_API_TO_READABLE,
}
