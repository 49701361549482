import { GridValidationErrorTypes } from '../../../common/constants/ValidationTypes'
import { FEE_MGMT_COLS_COLUMN_KEYS } from './Columns'

const ZIP_REGEX = /^\d{5}$/
const STATE_REGEX = /^[A-Z]{2}$/
const COUNTY_REGEX = /^[A-Za-z\s-]+$/

const DUPLICATE_STATE = 'DUPLICATE_STATE'
const DUPLICATE_COUNTY = 'DUPLICATE_COUNTY'
const DUPLICATE_ZIP = 'DUPLICATE_ZIP'
const DUPLICATE_VENDOR = 'DUPLICATE_VENDOR'
const DUPLICATE_BRANCH = 'DUPLICATE_BRANCH'
const INVALID_FIELD_FOR_LOCATION_TYPE = 'INVALID_FIELD_FOR_LOCATION_TYPE'

// Extend grid validation types with domain-specific ones
const FeeValidationErrorTypes = {
  ...GridValidationErrorTypes,
  INVALID_FIELD_FOR_LOCATION_TYPE,
  DUPLICATE_STATE,
  DUPLICATE_COUNTY,
  DUPLICATE_ZIP,
  DUPLICATE_VENDOR,
  DUPLICATE_BRANCH,
  INVALID_VALUE: 'INVALID_VALUE',
}

const STATE = 'MULTI-STATE'
const COUNTY = 'COUNTY'
const ZIP = 'ZIP'

const LOCATION_TYPES = { STATE, COUNTY, ZIP }

/* eslint-disable no-template-curly-in-string */
const ERROR_MESSAGE_TEMPLATES = {
  [FeeValidationErrorTypes.MISSING_VALUE]: {
    default: 'Required field',
  },
  [FeeValidationErrorTypes.INVALID_FORMAT]: {
    default: 'Invalid format',
    [FEE_MGMT_COLS_COLUMN_KEYS.FEE]: 'Must be a whole number',
    [FEE_MGMT_COLS_COLUMN_KEYS.DUE_DATE]: 'Must be a whole number',
  },
  [FeeValidationErrorTypes.DUPLICATE_STATE]: {
    default: "'${value}' is a duplicate state (see row(s) ${rowNumbers})",
  },
  [FeeValidationErrorTypes.DUPLICATE_COUNTY]: {
    default: "'${value}' is a duplicate county (see row(s) ${rowNumbers})",
  },
  [FeeValidationErrorTypes.DUPLICATE_ZIP]: {
    default: "'${value}' is a duplicate ZIP code (see row(s) ${rowNumbers})",
  },
  [FeeValidationErrorTypes.DUPLICATE_VENDOR]: {
    default: "'${value}' is a duplicate vendor (see row(s) ${rowNumbers})",
  },
  [FeeValidationErrorTypes.DUPLICATE_BRANCH]: {
    default: "'${value}' is a duplicate branch (see row(s) ${rowNumbers})",
  },
  [FeeValidationErrorTypes.DUPLICATE_ROW]: {
    default: 'Duplicate of row ${rowNumber}',
  },
  [FeeValidationErrorTypes.INVALID_FIELD_FOR_LOCATION_TYPE]: {
    default: '${value}',
  },
  [FeeValidationErrorTypes.INVALID_VALUE]: {
    default: 'Invalid value',
    [FEE_MGMT_COLS_COLUMN_KEYS.PRODUCT_NAME]: 'Invalid product name',
    [FEE_MGMT_COLS_COLUMN_KEYS.VENDOR]: 'Invalid vendor',
    [FEE_MGMT_COLS_COLUMN_KEYS.COUNTIES]: 'Invalid county',
    [FEE_MGMT_COLS_COLUMN_KEYS.STATES]: 'Invalid state',
    [FEE_MGMT_COLS_COLUMN_KEYS.ZIP_CODES]: 'ZIP must be 5 digits',
    [FEE_MGMT_COLS_COLUMN_KEYS.LOCATION_TYPE]: 'Must be MULTI-STATE, COUNTY, or ZIP',
  },
}

export {
  ZIP,
  COUNTY,
  STATE,
  ZIP_REGEX,
  STATE_REGEX,
  COUNTY_REGEX,
  FeeValidationErrorTypes,
  LOCATION_TYPES,
  ERROR_MESSAGE_TEMPLATES,
}
