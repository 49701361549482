import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container } from 'reactstrap'
import { Typography, Space } from 'antd'
import OverrideSummary from './OverrideSummary'
import RowCard from './RowCard'
import LoadingSpinner from './LoadingSpinner'
import { useFeeMgmtNavigation } from '../hooks/useFeeMgmtNavigation'
import { fetchOverrideSheets } from '../../../store/reducers/feeManagement/feeManagementSlice'
import {
  selectOverrideSheetsGroupedByType,
  selectOverrideSheetsLoading,
  selectOverrideSheetsError,
} from '../../../store/reducers/feeManagement/feeManagementSelectors'
import { OVERRIDE_TYPE_CONFIG } from '../constants/Breadcrumbs'
import { INITIAL_SUMMARY_PROPS } from '../constants/OverrideTypes'
import '../styles/FeeMgmt.scss'

const { Text, Title } = Typography

const FeeMgmt = () => {
  const dispatch = useDispatch()
  const { handleViewOverrideType } = useFeeMgmtNavigation()
  const [overrideSummaryProps, setOverrideSummaryProps] = useState({
    overrides: INITIAL_SUMMARY_PROPS,
    loading: true,
  })

  // Get data from Redux
  const overrideSheets = useSelector(selectOverrideSheetsGroupedByType)
  const isLoading = useSelector(selectOverrideSheetsLoading)
  const error = useSelector(selectOverrideSheetsError)

  // Fetch override sheets when component mounts
  useEffect(() => {
    dispatch(fetchOverrideSheets())
  }, [dispatch])

  useEffect(() => {
    if (overrideSheets) {
      setOverrideSummaryProps({
        overrides: overrideSheets,
        loading: isLoading,
      })
    }
  }, [overrideSheets, isLoading])

  const renderOverrideTypeCards = () => {
    return Object.entries(OVERRIDE_TYPE_CONFIG).map(([key, config]) => {
      const CardIcon = config.icon
      const leftContent = (
        <Space size="small" align="center">
          <div className="icon-wrapper">
            <CardIcon />
          </div>
          <div className="text-content">
            <Title level={4}>{config.title}</Title>
            <Text type="secondary">{config.description}</Text>
          </div>
        </Space>
      )

      const active = overrideSheets?.[key]?.filter((o) => o.status === 'active').length || 0
      const total = overrideSheets?.[key]?.length || 0
      const statsItems = [
        { label: 'Active', value: active },
        { label: 'Total', value: total },
      ]
      return (
        <RowCard
          key={key}
          className="override-type-card"
          leftContent={leftContent}
          statsItems={statsItems}
          onSelect={() => handleViewOverrideType(key)}
        />
      )
    })
  }

  if (error) {
    return (
      <Container fluid>
        <div>Error loading overrides: {error}</div>
      </Container>
    )
  }

  return (
    <Container fluid className="fee-management-container">
      <OverrideSummary overrides={overrideSummaryProps.overrides} loading={overrideSummaryProps.loading} />
      <div className="override-type-cards">
        {isLoading ? <LoadingSpinner text="Fetching override sheets..." /> : renderOverrideTypeCards()}
      </div>
    </Container>
  )
}

export default FeeMgmt
