const DELETE_COL = {
  readable: 'Delete',
  columnKey: 'delete',
  snakeCaseKey: 'delete',
}
const ERROR_COL = {
  readable: 'Error',
  columnKey: 'error',
  snakeCaseKey: 'error',
}
const PRODUCT_NAME_COL = {
  readable: 'Product Name',
  columnKey: 'productName',
  snakeCaseKey: 'product_id',
}
const BRANCH_COL = {
  readable: 'Branch',
  columnKey: 'branch',
  snakeCaseKey: 'branch_id',
}
const LOCATION_TYPE_COL = {
  readable: 'Location Type',
  columnKey: 'locationType',
  snakeCaseKey: 'location_type',
}
const VENDOR_COL = {
  readable: 'Vendor',
  columnKey: 'vendor',
  snakeCaseKey: 'panelist_id',
}
const STATE_COL = {
  readable: 'States',
  columnKey: 'states',
  snakeCaseKey: 'states',
}
const COUNTY_COL = {
  readable: 'Counties',
  columnKey: 'counties',
  snakeCaseKey: 'counties',
}
const ZIP_CODE_COL = {
  readable: 'Zip Codes',
  columnKey: 'zipCodes',
  snakeCaseKey: 'zips',
}
const FEE_COL = {
  readable: 'Fee',
  columnKey: 'fee',
  snakeCaseKey: 'fee',
}
const DUE_DATE_COL = {
  readable: 'Due Date',
  columnKey: 'dueDate',
  snakeCaseKey: 'due_date',
}

const ACTION_VALIDATION_OPTIONS = {
  existsInFile: false,
  supportsMultiValue: false,
}

const ACTION_GRID_COLUMN_OPTIONS = {
  shouldSplitCommaValues: false,
  includeInCsvExport: false,
  suppressQuotesInCsvExport: false,
  isEntityColumn: false,
}

const DEFAULT_VALIDATION_OPTIONS = {
  existsInFile: true,
  supportsMultiValue: true,
}

const DEFAULT_GRID_COLUMN_OPTIONS = {
  shouldSplitCommaValues: true,
  includeInCsvExport: true,
  suppressQuotesInCsvExport: false,
  isEntityColumn: false,
}

const FEE_MGMT_COLUMNS = {
  DELETE: {
    ...DELETE_COL,
    context: {
      validation: ACTION_VALIDATION_OPTIONS,
      customGridColumnOptions: ACTION_GRID_COLUMN_OPTIONS,
    },
  },
  ERROR: {
    ...ERROR_COL,
    context: {
      validation: ACTION_VALIDATION_OPTIONS,
      customGridColumnOptions: ACTION_GRID_COLUMN_OPTIONS,
    },
  },
  PRODUCT_NAME: {
    ...PRODUCT_NAME_COL,
    context: {
      validation: DEFAULT_VALIDATION_OPTIONS,
      customGridColumnOptions: { ...DEFAULT_GRID_COLUMN_OPTIONS, isEntityColumn: true },
    },
  },
  BRANCH: {
    ...BRANCH_COL,
    context: {
      validation: DEFAULT_VALIDATION_OPTIONS,
      customGridColumnOptions: { ...DEFAULT_GRID_COLUMN_OPTIONS, supportsMultiValue: false, isEntityColumn: true },
    },
  },
  VENDOR: {
    ...VENDOR_COL,
    context: {
      validation: {
        ...DEFAULT_VALIDATION_OPTIONS,
        supportsMultiValue: false,
      },
      customGridColumnOptions: {
        ...DEFAULT_GRID_COLUMN_OPTIONS,
        shouldSplitCommaValues: false,
        isEntityColumn: true,
      },
    },
  },
  LOCATION_TYPE: {
    ...LOCATION_TYPE_COL,
    context: {
      validation: {
        ...DEFAULT_VALIDATION_OPTIONS,
        supportsMultiValue: false,
      },
      customGridColumnOptions: {
        ...DEFAULT_GRID_COLUMN_OPTIONS,
        shouldSplitCommaValues: false,
      },
    },
  },
  STATES: {
    ...STATE_COL,
    context: {
      validation: DEFAULT_VALIDATION_OPTIONS,
      customGridColumnOptions: {
        ...DEFAULT_GRID_COLUMN_OPTIONS,
        isEntityColumn: true,
      },
    },
  },
  COUNTIES: {
    ...COUNTY_COL,
    context: {
      validation: DEFAULT_VALIDATION_OPTIONS,
      customGridColumnOptions: {
        ...DEFAULT_GRID_COLUMN_OPTIONS,
        isEntityColumn: true,
      },
    },
  },
  ZIP_CODES: {
    ...ZIP_CODE_COL,
    context: {
      validation: DEFAULT_VALIDATION_OPTIONS,
      customGridColumnOptions: {
        ...DEFAULT_GRID_COLUMN_OPTIONS,
        isEntityColumn: true,
      },
    },
  },
  FEE: {
    ...FEE_COL,
    context: {
      validation: {
        ...DEFAULT_VALIDATION_OPTIONS,
        supportsMultiValue: false,
      },
      customGridColumnOptions: {
        ...DEFAULT_GRID_COLUMN_OPTIONS,
        shouldSplitCommaValues: false,
        suppressQuotesInCsvExport: true,
      },
    },
  },
  DUE_DATE: {
    ...DUE_DATE_COL,
    context: {
      validation: {
        ...DEFAULT_VALIDATION_OPTIONS,
        supportsMultiValue: false,
      },
      customGridColumnOptions: {
        ...DEFAULT_GRID_COLUMN_OPTIONS,
        shouldSplitCommaValues: false,
        suppressQuotesInCsvExport: true,
      },
    },
  },
}

const FEE_MGMT_COLS_READABLE = Object.keys(FEE_MGMT_COLUMNS).reduce((acc, key) => {
  acc[key] = FEE_MGMT_COLUMNS[key].readable
  return acc
}, {})

const FEE_MGMT_COLS_COLUMN_KEYS = Object.keys(FEE_MGMT_COLUMNS).reduce((acc, key) => {
  acc[key] = FEE_MGMT_COLUMNS[key].columnKey
  return acc
}, {})

const FEE_MGMT_COLS_BY_READABLE = Object.keys(FEE_MGMT_COLUMNS).reduce((acc, key) => {
  acc[FEE_MGMT_COLUMNS[key].readable] = key
  return acc
}, {})

const ROW_NUMBER_COL = {
  readable: 'Row #',
  columnKey: 'rowNumber',
  context: {
    validation: {
      existsInFile: false,
      supportsMultiValue: false,
    },
    customGridColumnOptions: {
      shouldSplitCommaValues: false,
      includeInCsvExport: false,
    },
  },
}

const OVERRIDE_COLUMNS = [FEE_COL, DUE_DATE_COL]
const OVERRIDE_COLUMNS_READABLE = OVERRIDE_COLUMNS.map((column) => column.readable)

const OVERRIDE_COLUMNS_BY_SNAKE_KEY = OVERRIDE_COLUMNS.reduce((acc, column) => {
  acc[column.snakeCaseKey] = column
  return acc
}, {})

const OVERRIDE_COLUMNS_KEYS = OVERRIDE_COLUMNS.map((column) => column.columnKey)

const OVERRIDE_COLUMNS_READABLE_TO_COLUMN_KEY = OVERRIDE_COLUMNS.reduce((acc, column) => {
  acc[column.readable] = column.columnKey
  return acc
}, {})

const OVERRIDE_COLUMNS_KEY_TO_SNAKE_KEY = OVERRIDE_COLUMNS.reduce((acc, column) => {
  acc[column.columnKey] = column.snakeCaseKey
  return acc
}, {})

const isEntityColumn = (columnKey) => {
  return (
    Object.values(FEE_MGMT_COLUMNS).find((column) => column.columnKey === columnKey)?.context.customGridColumnOptions
      .isEntityColumn || false
  )
}

export {
  FEE_COL,
  DUE_DATE_COL,
  FEE_MGMT_COLUMNS,
  FEE_MGMT_COLS_READABLE,
  FEE_MGMT_COLS_COLUMN_KEYS,
  FEE_MGMT_COLS_BY_READABLE,
  ROW_NUMBER_COL,
  OVERRIDE_COLUMNS,
  OVERRIDE_COLUMNS_READABLE,
  OVERRIDE_COLUMNS_BY_SNAKE_KEY,
  OVERRIDE_COLUMNS_KEYS,
  OVERRIDE_COLUMNS_READABLE_TO_COLUMN_KEY,
  OVERRIDE_COLUMNS_KEY_TO_SNAKE_KEY,
  isEntityColumn,
}
