import { useMemo, useCallback } from 'react'
import { WarningOutlined, DeleteOutlined } from '@ant-design/icons'
import { LOCATION_TYPES } from '../constants/Validation'
import { FEE_MGMT_COLUMNS, ROW_NUMBER_COL, isEntityColumn, OVERRIDE_COLUMNS } from '../constants/Columns'
import { REQUIRED_COLUMNS_BY_OVERRIDE_TYPE } from '../constants/OverrideTypes'
import IconHeader from '../IconHeader'
import GridSelectFilter from '../GridSelectFilter'
import FindReplaceFilter from '../FindReplaceFilter'
import EntitySelectCellEditor from '../components/EntitySelectCellEditor'
import SelectCellEditor from '../components/SelectCellEditor'
import {
  ErrorCellRenderer,
  CellWithErrorTooltip,
  DeleteCellRenderer,
  LocationTypeCellRenderer,
  CommaSeparatedValuesCellRenderer,
} from '../components/CellRenderers'

const useGridColumns = ({ onHeaderDeleteToggle, gridContext, overrideType, selectedFields }) => {
  // Track column existence based on override type and selected fields
  const requiredColumnKeys = useMemo(() => {
    // Get keys specific to the current override type
    const overrideSpecificKeys = REQUIRED_COLUMNS_BY_OVERRIDE_TYPE[overrideType] || []

    // Get keys for selected override fields (like Fee, Due Date)
    const selectedOverrideKeys = OVERRIDE_COLUMNS.filter((column) => selectedFields?.includes(column.columnKey)) // Add safety check for selectedFields
      .map((column) => column.columnKey)

    // Combine and deduplicate
    return [...new Set([...overrideSpecificKeys, ...selectedOverrideKeys])]
  }, [overrideType, selectedFields])

  // Common column configuration factory
  const createBaseColumn = useCallback((columnConfig) => {
    const baseConfig = {
      colId: columnConfig.columnKey,
      field: columnConfig.columnKey,
      headerName: columnConfig.readable,
      editable: true,
      cellClass: (params) => {
        const errorField = columnConfig.columnKey
        if (!params.data?.validationErrors?.[errorField]) {
          return ''
        }

        // Check only for details with error values
        const hasErrorDetails =
          params.data.validationErrors[errorField].details &&
          Object.keys(params.data.validationErrors[errorField].details).length > 0

        return hasErrorDetails ? 'cell-has-error' : ''
      },
      context: {
        ...columnConfig.context.customGridColumnOptions,
      },
    }

    return baseConfig
  }, [])

  // Factory for creating select filter columns
  const createSelectFilterColumn = useCallback(
    (columnConfig, options, doesFilterPassFn) => {
      const baseConfig = createBaseColumn(columnConfig)
      return {
        ...baseConfig,
        filter: GridSelectFilter,
        filterParams: {
          options,
          doesFilterPassFn,
        },
      }
    },
    [createBaseColumn],
  )

  // Factory for creating find/replace filter columns
  const createFindReplaceColumn = useCallback(
    (columnConfig) => {
      const baseConfig = createBaseColumn(columnConfig)

      // Use the isEntityColumn function to determine if this column should use EntitySelectCellEditor
      if (isEntityColumn(columnConfig.columnKey)) {
        return {
          ...baseConfig,
          filter: FindReplaceFilter,
          filterParams: {
            context: gridContext,
          },
          cellEditor: EntitySelectCellEditor,
          cellEditorPopup: false,
        }
      }

      return {
        ...baseConfig,
        filter: FindReplaceFilter,
        filterParams: {
          buttons: ['reset', 'apply'],
          closeOnApply: true,
          context: gridContext,
        },
      }
    },
    [createBaseColumn, gridContext],
  )

  const createErrorColumn = useCallback(() => {
    const baseErrorColumn = createSelectFilterColumn(
      FEE_MGMT_COLUMNS.ERROR,
      [
        { value: 'errors', label: 'Has Errors' },
        { value: 'noErrors', label: 'No Errors' },
      ],
      (value, data, filterText) => {
        if (value === 'validation') {
          const errorType = filterText
          const cellErrors = data.validationErrors || {}
          return Object.values(cellErrors).some((error) => error.errorTypes && error.errorTypes.has(errorType))
        }
        if (value === 'errors') {
          return (
            data.isDuplicateRow ||
            (data.validationErrors && Object.values(data.validationErrors).some((col) => col.errorTypes?.size > 0))
          )
        }
        if (value === 'noErrors') {
          return (
            !data.isDuplicateRow &&
            (!data.validationErrors || Object.values(data.validationErrors).every((col) => !col.errorTypes?.size))
          )
        }
        return true
      },
    )

    return {
      ...baseErrorColumn,
      headerComponent: IconHeader,
      headerComponentParams: {
        icon: WarningOutlined,
        tooltip: 'Error',
        showMenu: true,
      },
      width: 48,
      cellRenderer: ErrorCellRenderer,
      cellClass: 'action-cell align-left',
      editable: false,
      sort: 'desc',
      sortIndex: 0,
      valueGetter: (params) => {
        const hasErrors =
          params.data.validationErrors &&
          Object.values(params.data.validationErrors).some((col) => col.errorTypes?.size > 0)
        return hasErrors ? 1 : 0
      },
    }
  }, [createSelectFilterColumn])

  // Add a new helper to create columns with error tooltips
  const createColumnWithErrorTooltip = useCallback(
    (columnConfig) => {
      const baseConfig = createFindReplaceColumn(columnConfig)

      return {
        ...baseConfig,
        cellRenderer: CellWithErrorTooltip,
      }
    },
    [createFindReplaceColumn],
  )

  // Memoize column definitions
  const columnDefs = useMemo(() => {
    const baseColumns = [
      // Row number column
      {
        colId: ROW_NUMBER_COL.columnKey,
        field: ROW_NUMBER_COL.columnKey,
        headerName: ROW_NUMBER_COL.readable,
        width: 48,
        editable: false,
        filter: false,
        sortable: true,
        cellClass: 'align-right',
      },
      // Delete column
      {
        ...createSelectFilterColumn(
          FEE_MGMT_COLUMNS.DELETE,
          [
            { value: 'marked', label: 'Marked' },
            { value: 'unmarked', label: 'Unmarked' },
          ],
          (value, data) => {
            if (value === 'marked') {
              return data.isDuplicateRow || data.userMarkedForDelete
            }
            if (value === 'unmarked') {
              return !data.isDuplicateRow && !data.userMarkedForDelete
            }
            return true
          },
        ),
        headerComponent: IconHeader,
        headerComponentParams: {
          icon: DeleteOutlined,
          tooltip: 'Delete',
          showMenu: true,
          onClick: () => {
            onHeaderDeleteToggle()
          },
        },
        cellRenderer: DeleteCellRenderer,
        cellClass: 'action-cell align-left',
        editable: false,
        width: 48,
        onCellClicked: (params) => {
          // Skip action if it's a duplicate row
          if (params.data?.isDuplicateRow) {
            return
          }
          const context = params.context || {}
          context.onToggleDelete([params.data.rowNumber], !params.data.userMarkedForDelete)
        },
      },
      // Error column
      createErrorColumn(),
      // Product Name column (always shown as it's common)
      createColumnWithErrorTooltip(FEE_MGMT_COLUMNS.PRODUCT_NAME),
    ]

    // Dynamically create columns based on required keys
    const dynamicColumns = requiredColumnKeys.reduce((cols, key) => {
      let columnDef

      switch (key) {
        case FEE_MGMT_COLUMNS.BRANCH.columnKey:
          columnDef = {
            ...createColumnWithErrorTooltip(FEE_MGMT_COLUMNS.BRANCH),
            cellRenderer: CommaSeparatedValuesCellRenderer,
          }
          break
        case FEE_MGMT_COLUMNS.VENDOR.columnKey:
          columnDef = createColumnWithErrorTooltip(FEE_MGMT_COLUMNS.VENDOR)
          break
        case FEE_MGMT_COLUMNS.LOCATION_TYPE.columnKey:
          columnDef = {
            ...createSelectFilterColumn(
              FEE_MGMT_COLUMNS.LOCATION_TYPE,
              Object.values(LOCATION_TYPES).map((type) => ({ value: type, label: type })),
              (value, data) => (value === 'all' ? true : data.locationType === value),
            ),
            cellClass: (params) => {
              const errorField = FEE_MGMT_COLUMNS.LOCATION_TYPE.columnKey
              const errors = params.data?.validationErrors?.[errorField]

              if (!errors) {
                return ''
              }

              // Check only details
              const hasErrorDetails = errors.details && Object.keys(errors.details).length > 0

              return hasErrorDetails ? 'cell-has-error' : ''
            },
            width: 120,
            cellRenderer: LocationTypeCellRenderer,
            cellEditor: SelectCellEditor,
            cellEditorParams: {
              options: Object.values(LOCATION_TYPES).map((type) => ({ value: type, label: type })),
              placeholder: 'Select location type',
            },
            cellEditorPopup: false,
          }
          break
        case FEE_MGMT_COLUMNS.STATES.columnKey:
          columnDef = {
            ...createColumnWithErrorTooltip(FEE_MGMT_COLUMNS.STATES),
            cellRenderer: CommaSeparatedValuesCellRenderer,
          }
          break
        case FEE_MGMT_COLUMNS.COUNTIES.columnKey:
          columnDef = {
            ...createColumnWithErrorTooltip(FEE_MGMT_COLUMNS.COUNTIES),
            cellRenderer: CommaSeparatedValuesCellRenderer,
          }
          break
        case FEE_MGMT_COLUMNS.ZIP_CODES.columnKey:
          columnDef = {
            ...createColumnWithErrorTooltip(FEE_MGMT_COLUMNS.ZIP_CODES),
            cellRenderer: CommaSeparatedValuesCellRenderer,
          }
          break
        case FEE_MGMT_COLUMNS.FEE.columnKey:
          columnDef = createColumnWithErrorTooltip(FEE_MGMT_COLUMNS.FEE)
          break
        case FEE_MGMT_COLUMNS.DUE_DATE.columnKey:
          columnDef = createColumnWithErrorTooltip(FEE_MGMT_COLUMNS.DUE_DATE)
          break
        default:
          // Handle unknown keys or log a warning if necessary
          console.warn(`Unknown required column key encountered: ${key}`)
          break
      }

      if (columnDef) {
        cols.push(columnDef)
      }

      return cols
    }, [])

    return [...baseColumns, ...dynamicColumns]
  }, [
    onHeaderDeleteToggle,
    createSelectFilterColumn,
    createColumnWithErrorTooltip,
    createErrorColumn,
    requiredColumnKeys,
  ])

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      minWidth: 120,
      resizable: true,
      suppressMovable: true,
      menuTabs: ['filterMenuTab'],
      filter: true,
      filterParams: {
        buttons: ['reset', 'apply'],
        closeOnApply: true,
        maxNumConditions: 1,
      },
      sortable: true,
      sortingOrder: ['asc', 'desc', null],
      unSortIcon: true,
      singleClickEdit: true,
    }),
    [],
  )

  return { columnDefs, defaultColDef }
}

export default useGridColumns
