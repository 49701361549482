import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import API, { API_VERSION, REQUEST_METHOD } from '../../../api'
import { OVERRIDE_COLUMNS_BY_SNAKE_KEY } from '../../../pages/feeManagement/constants/Columns'
import { OVERRIDE_TYPES, API_OVERRIDE_TYPE_MAPPING } from '../../../pages/feeManagement/constants/OverrideTypes'

export const initialState = {
  overrideSheets: {
    items: [],
    isLoading: false,
    error: null,
  },
  overrideData: {
    data: null,
    isLoading: false,
    error: null,
  },
  createOverride: {
    isLoading: false,
    error: null,
    success: false,
    createdSheetId: null,
    result: null,
  },
  mappingData: {
    data: {
      products: [],
      panelists: [],
      state_and_counties_map: {},
      branches: [],
    },
    isLoading: false,
    error: null,
    fetchedEntityTypes: [],
  },
  updateOverride: {
    isLoading: false,
    error: null,
    success: false,
  },
}

// Helper function to determine override type from entity_types
const getOverrideType = (entityTypes) => {
  const sortedTypes = [...entityTypes].sort()
  const typeString = sortedTypes.join(',')

  if (typeString === API_OVERRIDE_TYPE_MAPPING.VENDOR_LOCATION.sort().join(',')) {
    return OVERRIDE_TYPES.VENDOR_LOCATION.value
  } else if (typeString === API_OVERRIDE_TYPE_MAPPING.VENDOR.sort().join(',')) {
    return OVERRIDE_TYPES.VENDOR.value
  } else if (typeString === API_OVERRIDE_TYPE_MAPPING.LOCATION.sort().join(',')) {
    return OVERRIDE_TYPES.LOCATION.value
  } else if (typeString === API_OVERRIDE_TYPE_MAPPING.BRANCH_VENDOR_LOCATION.sort().join(',')) {
    return OVERRIDE_TYPES.BRANCH_VENDOR_LOCATION.value
  } else if (typeString === API_OVERRIDE_TYPE_MAPPING.BRANCH_VENDOR.sort().join(',')) {
    return OVERRIDE_TYPES.BRANCH_VENDOR.value
  } else if (typeString === API_OVERRIDE_TYPE_MAPPING.BRANCH_LOCATION.sort().join(',')) {
    return OVERRIDE_TYPES.BRANCH_LOCATION.value
  } else if (typeString === API_OVERRIDE_TYPE_MAPPING.BRANCH.sort().join(',')) {
    return OVERRIDE_TYPES.BRANCH.value
  }
  return null
}

export const fetchOverrideSheets = createAsyncThunk(
  'feeManagement/fetchOverrideSheets',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await API('overrides/sheets/', API_VERSION.TWO).request(REQUEST_METHOD.GET)

      // Extract all unique entity types directly from the API response
      const allEntityTypes = new Set()
      response.sheets.forEach((sheet) => {
        if (Array.isArray(sheet.entity_types)) {
          sheet.entity_types.forEach((type) => allEntityTypes.add(type))
        }
      })

      // Convert Set to Array and fetch mapping data if needed
      const entityTypesArray = Array.from(allEntityTypes)
      if (entityTypesArray.length > 0) {
        dispatch(fetchMappingData(entityTypesArray))
      }

      // Transform the response to include derived override type
      const transformedSheets = response.sheets.map((sheet) => {
        const overrideType = getOverrideType(sheet.entity_types)
        const transformed = {
          id: sheet.id,
          title: sheet.description,
          status: sheet.active ? 'active' : 'inactive',
          overrideType,
          overriddenFields: sheet.data_types.map((type) => OVERRIDE_COLUMNS_BY_SNAKE_KEY[type]?.columnKey || type),
          created: new Date(sheet.created).toLocaleDateString(),
          createdBy: sheet.created_by,
          lenderId: sheet.lender_id,
          // Store the original entity_types for reference
          entityTypes: sheet.entity_types,
        }
        return transformed
      })

      return transformedSheets
    } catch (err) {
      console.error('Error fetching override sheets:', err)
      return rejectWithValue(err.errors?._error || 'Failed to fetch override sheets')
    }
  },
)

export const fetchOverrideData = (sheetId) => async (dispatch) => {
  try {
    dispatch(setOverrideDataLoading(true))
    const api = API(`overrides/sheets/${sheetId}/overrides.json`, API_VERSION.TWO)
    const response = await api.request(REQUEST_METHOD.GET, {}, {}, true)
    dispatch(setOverrideData(response))
  } catch (error) {
    dispatch(setOverrideDataError(error.message))
  } finally {
    dispatch(setOverrideDataLoading(false))
  }
}

export const updateOverrideSheet = createAsyncThunk(
  'feeManagement/updateOverrideSheet',
  async (payload, { rejectWithValue }) => {
    try {
      const { id, ...rest } = payload
      const response = await API(`overrides/sheets/${id}`, API_VERSION.TWO).request(REQUEST_METHOD.PATCH, {}, rest)
      return response
    } catch (err) {
      console.error('Error updating override sheet:', err)
      return rejectWithValue(err.errors?._error || 'Failed to update override sheet')
    }
  },
)

export const createOverrideSheet = createAsyncThunk(
  'feeManagement/createOverrideSheet',
  async (payload, { rejectWithValue }) => {
    try {
      const { description, entityTypes, dataTypes, overrides, active = false } = payload

      const requestBody = {
        description,
        entity_types: entityTypes,
        data_types: dataTypes,
        active,
        overrides,
      }

      const response = await API('overrides/sheets/', API_VERSION.TWO).request(REQUEST_METHOD.POST, {}, requestBody)

      return response
    } catch (err) {
      console.error('Error creating override sheet:', err)
      return rejectWithValue(err.errors?._error || 'Failed to create override sheet')
    }
  },
)

export const fetchMappingData = createAsyncThunk(
  'feeManagement/fetchMappingData',
  async (entityTypes, { getState, rejectWithValue }) => {
    if (!entityTypes || !entityTypes.length) {
      return { newData: {}, newEntityTypes: [] }
    }

    // Filter out entity types we've already fetched
    const state = getState()
    const fetchedEntityTypes = state.feeManagement.mappingData.fetchedEntityTypes
    const newEntityTypes = entityTypes.filter((type) => !fetchedEntityTypes.includes(type))

    // If we've already fetched all these entity types, no need to make another request
    if (newEntityTypes.length === 0) {
      return { newData: {}, newEntityTypes: [] }
    }

    try {
      const queryString = `entity_types=${newEntityTypes.join(',')}`
      const response = await API(`overrides/mapping-data?${queryString}`, API_VERSION.TWO).request(REQUEST_METHOD.GET)

      return {
        newData: response,
        newEntityTypes,
      }
    } catch (err) {
      return rejectWithValue(err.errors?._error || 'Failed to fetch mapping data')
    }
  },
)

const feeManagementSlice = createSlice({
  name: 'feeManagement',
  initialState,
  reducers: {
    resetFeeManagement: () => initialState,
    setOverrideData: (state, action) => {
      state.overrideData.data = action.payload
    },
    setOverrideDataLoading: (state, action) => {
      state.overrideData.isLoading = action.payload
    },
    setOverrideDataError: (state, action) => {
      state.overrideData.error = action.payload
    },
    resetCreateOverrideState: (state) => {
      state.createOverride = {
        isLoading: false,
        error: null,
        success: false,
        createdSheetId: null,
        result: null,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOverrideSheets.pending, (state) => {
        state.overrideSheets.isLoading = true
        state.overrideSheets.error = null
      })
      .addCase(fetchOverrideSheets.fulfilled, (state, action) => {
        state.overrideSheets.items = action.payload
        state.overrideSheets.isLoading = false
        state.overrideSheets.error = null
      })
      .addCase(fetchOverrideSheets.rejected, (state, action) => {
        state.overrideSheets.isLoading = false
        state.overrideSheets.error = action.payload
      })
      .addCase(createOverrideSheet.pending, (state) => {
        state.createOverride.isLoading = true
        state.createOverride.error = null
        state.createOverride.success = false
      })
      .addCase(createOverrideSheet.fulfilled, (state, action) => {
        state.createOverride.isLoading = false
        state.createOverride.success = true
        state.createOverride.createdSheetId = action.payload.sheet_id
        state.createOverride.result = {
          sheetId: action.payload.sheet_id,
          updatedCount: action.payload.updated_count || 0,
          insertedCount: action.payload.inserted_count || 0,
        }
      })
      .addCase(createOverrideSheet.rejected, (state, action) => {
        state.createOverride.isLoading = false
        state.createOverride.error = action.payload
        state.createOverride.success = false
      })
      .addCase(fetchMappingData.pending, (state) => {
        state.mappingData.isLoading = true
        state.mappingData.error = null
      })
      .addCase(fetchMappingData.fulfilled, (state, action) => {
        const { newData, newEntityTypes } = action.payload

        // If there's no new data, just update loading state
        if (!newData.products && !newData.panelists && !newData.state_and_counties_map) {
          state.mappingData.isLoading = false
          return
        }

        // Merge products
        if (newData.products) {
          const existingProductIds = new Set(state.mappingData.data.products.map((p) => p.id))
          const newProducts = newData.products.filter((p) => !existingProductIds.has(p.id))
          state.mappingData.data.products = [...state.mappingData.data.products, ...newProducts]
        }

        // Merge panelists
        if (newData.panelists) {
          const existingPanelistIds = new Set(state.mappingData.data.panelists.map((p) => p.id))
          const newPanelists = newData.panelists.filter((p) => !existingPanelistIds.has(p.id))
          state.mappingData.data.panelists = [...state.mappingData.data.panelists, ...newPanelists]
        }

        // Merge state_and_counties_map
        if (newData.state_and_counties_map) {
          state.mappingData.data.state_and_counties_map = {
            ...state.mappingData.data.state_and_counties_map,
            ...newData.state_and_counties_map,
          }
        }

        // Merge branches
        if (newData.branches) {
          state.mappingData.data.branches = [...state.mappingData.data.branches, ...newData.branches]
        }

        // Add new entity types to the fetched list
        state.mappingData.fetchedEntityTypes = [...state.mappingData.fetchedEntityTypes, ...newEntityTypes]
        state.mappingData.isLoading = false
      })
      .addCase(fetchMappingData.rejected, (state, action) => {
        state.mappingData.isLoading = false
        state.mappingData.error = action.payload
      })
      .addCase(updateOverrideSheet.pending, (state) => {
        state.updateOverride.isLoading = true
        state.updateOverride.error = null
      })
      .addCase(updateOverrideSheet.fulfilled, (state, action) => {
        state.updateOverride.isLoading = false
        state.updateOverride.error = null
        state.updateOverride.success = true
      })
      .addCase(updateOverrideSheet.rejected, (state, action) => {
        state.updateOverride.isLoading = false
        state.updateOverride.error = action.payload
        state.updateOverride.success = false
      })
  },
})

export const {
  resetFeeManagement,
  setOverrideData,
  setOverrideDataLoading,
  setOverrideDataError,
  resetCreateOverrideState,
} = feeManagementSlice.actions

export default feeManagementSlice.reducer
