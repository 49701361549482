import { GoldOutlined, TeamOutlined, EnvironmentOutlined } from '@ant-design/icons'
import { OVERRIDE_TYPES } from './OverrideTypes'

export const OVERRIDE_TYPE_CONFIG = {
  [OVERRIDE_TYPES.BRANCH.value]: {
    title: OVERRIDE_TYPES.BRANCH.readable,
    icon: EnvironmentOutlined,
    description: 'Manage fee and due date overrides for specific branches',
  },
  [OVERRIDE_TYPES.BRANCH_LOCATION.value]: {
    title: OVERRIDE_TYPES.BRANCH_LOCATION.readable,
    icon: EnvironmentOutlined,
    description: 'Manage fee and due date overrides for specific branches and locations',
  },
  [OVERRIDE_TYPES.BRANCH_VENDOR.value]: {
    title: OVERRIDE_TYPES.BRANCH_VENDOR.readable,
    icon: EnvironmentOutlined,
    description: 'Manage fee and due date overrides for specific branches and vendors',
  },
  [OVERRIDE_TYPES.BRANCH_VENDOR_LOCATION.value]: {
    title: OVERRIDE_TYPES.BRANCH_VENDOR_LOCATION.readable,
    icon: EnvironmentOutlined,
    description: 'Manage fee and due date overrides for specific branches, vendors, and locations',
  },
  [OVERRIDE_TYPES.VENDOR_LOCATION.value]: {
    title: OVERRIDE_TYPES.VENDOR_LOCATION.readable,
    icon: GoldOutlined,
    description: 'Manage fee and due date overrides for specific vendors at specific locations',
  },
  [OVERRIDE_TYPES.VENDOR.value]: {
    title: OVERRIDE_TYPES.VENDOR.readable,
    icon: TeamOutlined,
    description: 'Manage fee and due date overrides for specific vendors',
  },
  [OVERRIDE_TYPES.LOCATION.value]: {
    title: OVERRIDE_TYPES.LOCATION.readable,
    icon: EnvironmentOutlined,
    description: 'Manage fee and due date overrides for specific locations',
  },
}
