import { useState, useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { OVERRIDE_TYPES } from '../constants/OverrideTypes'
import { OVERRIDE_TYPE_CONFIG } from '../constants/Breadcrumbs'

export const useFeeMgmtNavigation = () => {
  const history = useHistory()
  const location = useLocation()
  const [activeSection, setActiveSection] = useState(null)

  const sectionTitle = useMemo(() => {
    return activeSection ? OVERRIDE_TYPES[activeSection].readable : ''
  }, [activeSection])

  const view = useMemo(() => {
    return location.pathname.includes('/create') ? 'create' : 'list'
  }, [location])

  const handleAddOverride = useCallback(
    (type) => {
      if (!type) {
        return
      }
      setActiveSection(type)
      history.push(`/fee-management/overrides/${type}/create`)
    },
    [history],
  )

  const handleBack = useCallback(() => {
    setActiveSection(null)
    history.goBack()
  }, [history])

  const handleBackFromOverride = useCallback(
    (overrideType) => {
      if (overrideType) {
        history.push(`/fee-management/overrides/${overrideType}`)
      } else {
        history.push('/fee-management')
      }
    },
    [history],
  )

  const handleViewOverride = useCallback(
    (overrideId) => {
      history.push(`/fee-management/override/${overrideId}`)
    },
    [history],
  )

  const handleViewOverrideType = useCallback(
    (type) => {
      history.push(`/fee-management/overrides/${type}`)
    },
    [history],
  )

  const getOverrideTypeMenuItems = useCallback((currentType) => {
    return Object.entries(OVERRIDE_TYPE_CONFIG)
      .filter(([key]) => key !== currentType)
      .map(([key, config]) => ({
        text: config.title,
        path: `/fee-management/overrides/${key}`,
        icon: config.icon,
      }))
  }, [])

  return {
    activeSection,
    sectionTitle,
    view,
    handleAddOverride,
    handleBack,
    handleBackFromOverride,
    handleViewOverride,
    handleViewOverrideType,
    getOverrideTypeMenuItems,
  }
}
